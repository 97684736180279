<template>
  <!-- <b-card class="">
    <div class="mb-1">
      <strong>
        Client Messages
      </strong>
    </div>
    <b-table
      small
      :fields="fields"
      :items="messages"
      responsive="sm"
    >

    </b-table>
  </b-card> -->
  <Chat />
</template>

<script>
import {
  BTable, BCard,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import Chat from '../chat/Chat.vue'

export default {
  components: {
    HwaButton,
    BTable,
    BCard,
    Chat
  },
  mixins: [showToast],
  data() {
    return {
      fields: ['message', {key:'sender',label:"Sender",sortable:true}, 'email'],
      messages: [],
    }
  },
  mounted() {
    this.getMessages()
  },
  methods: {
    getMessages() {
      this.$http.get('/api/messages')
        .then(res => {
          this.messages = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve messages')
        })
    },
  },
}
</script>
